/* Themes */

@font-face {
  font-family: 'Metropolis';
  src: url('/fonts/metropolis/Metropolis-Regular.otf');
}
@font-face {
  font-family: 'Metropolis';
  src: url('/fonts/metropolis/Metropolis-RegularItalic.otf');
  font-style: italic;
}
@font-face {
  font-family: 'Metropolis';
  src: url('/fonts/metropolis/Metropolis-SemiBold.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Metropolis';
  src: url('/fonts/metropolis/Metropolis-SemiBoldItalic.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Metropolis';
  src: url('/fonts/metropolis/Metropolis-Black.otf');
  font-weight: bold;
}
@font-face {
  font-family: 'Metropolis';
  src: url('/fonts/metropolis/Metropolis-BlackItalic.otf');
  font-weight: bold;
  font-style: italic;
}

:root {
  // Theme color pallet
  --primaryColor--light: #aecfe2;
  --primaryColor: #064a83;
  --primaryColor--dark: #021a2f;
  --secondaryColor--light: #bbebfb;
  --secondaryColor: #42c7f3;
  --secondaryColor--dark: #39a7cb;
  --accentColor--light: #e1efb2;
  --accentColor: #abd22a;
  --accentColor--dark: #7e8f1d;
  --extraColor1--light: #e1c1e8;
  --extraColor1: #ac52c0;
  --extraColor1--dark: #8a3e92;
  --extraColor2--light: #fbb7b7;
  --extraColor2: #f53737;
  --extraColor2--dark: #b42525;
  --extraColor3--light: #fee0a3;
  --extraColor3: #fda800;
  --extraColor3--dark: #ba7200;

  // Status colors
  --successColor--light: #e1efb2;
  --successColor: #abd22a;
  --successColor--dark: #7e8f1d;
  --errorColor--light: #fbb7b7;
  --errorColor: #f53737;
  --errorColor--dark: #b42525;
  --warningColor--light: #fee0a3;
  --warningColor: #fda800;
  --warningColor--dark: #ba7200;

  // Text colors
  --linkColor: #1e7baf;
  --linkColor--hover: #064a83;
  --textColor--light: #ced2d6;
  --textColor: #798490;
  --textColor--dark: #392f33;

  // Background
  --backgroundColor: #e0e8ef;

  // Spacing variable
  --gutter: 0.5rem;
}
