.campaignModal__routeContainer {
  box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1);
  outline-color: var(--secondaryColor);
  outline-offset: 0;
  border: 2px solid var(--primaryColor--light);
  padding: 10px;
}

.hidden {
  display: none !important;
}

.campaignModal__extraGutterTop {
  margin-top: calc(var(--gutter) * 3);
}
